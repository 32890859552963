import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-confirm-delete',
    templateUrl: './confirm-delete.component.html',
    styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {
    @Input() open?: boolean;
    @Input() positionClass?: 'left' | 'right' | 'center' = 'center';
    @Input() positionClassV?: 'top' | 'bottom' | 'middle' = 'middle';
    @Input() title?: string = 'Ben je zeker?';
    @Input() descr?: string =
        'Ben je zeker dat je dit item wil verwijderen? Deze actie kan niet ongedaan gemaakt worden.';
    @Input() cancelButton?: string = 'Nee, annuleren';
    @Input() confirmButton?: string = 'Ja, verwijderen';

    @Output() cancel = new EventEmitter();
    @Output() confirm = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}
}
