<span class="close-modal-outside"><i (click)="dismiss()" class="im-icon im-icon-x-light"></i></span>
<div class="modal-wrap">

    @if (!ready) {
        <app-spinner [centerVertically]="true"></app-spinner>
    } @else {
        <div class="d-flex detail-modal">

            <!-- start form -->
            <div class="d-flex flex-column w-100 flex-grow-1 scroll-y">
                @if (loading) {
                    <div style="height: 100%;">
                        <app-spinner [centerVertically]="true"></app-spinner>
                    </div>
                }
               
                <!-- header -->
                <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                    <h1 style="min-height: 36px;" class="ellipsis">
                        @if (mode === 'create') {
                            Nieuwsitem toevoegen
                        } @else {
                            {{data?.titel}}
                        }
                    </h1>

                    <div class="h-100 d-flex align-items-center" [attr.inert]="submitting || submittingImages ? '' : null">
                        @if (mode === 'create') {
                            <button class="button tertiary" (click)="dismiss()" [class.disabled]="saving"><span>Annuleren</span></button>
                            <app-button classes="primary-default ml-xs" icon="check-round-filled" text="Bewaren" [disabled]="submitting" [submitting]="submitting" (emitClick)="submit()"></app-button>
                        } @else if(mode === 'view') {
                            <button class="button tertiary ml-xs" (click)="mode = 'edit'" [class.disabled]="saving"><i class="im-icon im-icon-pencil icon-l mr-xxxs"></i><span>Wijzigen</span></button>
                            <div class="position-relative ml-xs" appClickOutside (clickOutside)="confirmDelete = false">
                                <button class="button tertiary delete-red" (click)="confirmDelete = true" [class.disabled]="submitting"><i class="im-icon im-icon-trash delete-red icon-l mr-xxxs" style="transform: translateY(-1px);"></i><span>Verwijderen</span></button>
                                <app-confirm-delete (confirm)="deleteItem();" (cancel)="confirmDelete = false" [open]="confirmDelete" positionClass="left" positionClassV="bottom"></app-confirm-delete>
                            </div>
                        } @else {
                            <button class="button tertiary" (click)="mode = 'view'; resetForm()" [class.disabled]="saving"><span>Annuleren</span></button>
                            <app-button classes="primary-default ml-xs" icon="check-round-filled" text="Bewaren" [disabled]="submitting" [submitting]="submitting" (emitClick)="submit()"></app-button>
                        }
                    </div>
                   
                </div>



                <div class="detail-modal-body">
                    <!-- content -->
                    <section class="d-flex flex-column gap-32">
                        <div class="display-content news-content-row">
                            @if (mode === 'view') {
                                <div class="display-label">Titel</div>
                                <div class="display-value d-flex flex-row">
                                    <span class="page-content" [innerHTML]="data?.titel | display | safe : 'html'"></span>
                                </div>
                            } @else {
                                <app-field-text 
                                    [model]="FORM?.titel" 
                                    (modelChange)="FORM.titel = $event; formChange();" 
                                    [label]="'Titel'"
                                    [expanded]="true"
                                    [error]="validation?.titel">
                                </app-field-text>
                            }
                        </div>
                        <div class="display-content news-content-row">
                            @if (mode === 'view') {
                                <div class="display-label">Inhoud</div>
                                <div class="display-value d-flex flex-row" style="border: 1px solid #D7DADD; border-radius: 4px; padding: 24px;">
                                    <div class="page-content meldpunt-richtext">
                                        <span class="page-content" [innerHTML]="data?.inhoud | display | safe : 'html'"></span>
                                    </div>
                                </div>

                            } @else {
                                <app-field-richtext 
                                    class="meldpunt-richtext"
                                    [model]="FORM?.inhoud" 
                                    (modelChange)="FORM.inhoud = $event; formChange();" 
                                    [label]="'Inhoud'"
                                    [expanded]="true"
                                    [error]="validation?.inhoud"
                                    [meldpuntEditor]="true"
                                ></app-field-richtext>
                            }
                        </div>
                        <div class="display-content news-content-row image-cropper-wrap">
                            <!-- FILE UPLOAD -->

                            <div class="d-flex flex-column field">
                                <div class="display-label field" >Afbeelding <i class="im-icon im-icon-info icon--size-12 ml-4" placement="top" ngbTooltip="Upload een afbeelding van minstens 1800px breed en 1200px hoog voor het beste resultaat." tooltipClass="meldpunt-tooltip"></i></div>
                                
                                <div class="d-flex gap-16 align-items-center">
                                    <!-- <div class="file-input display-value" ngfSelect (fileChange)="imageSelect($event)" accept="image/*" ngfDrop> -->
                                    <div class="file-input display-value" ngfSelect (fileChange)="imageSelect($event)" accept="image/*" ngfDrop>
                                        @if (FORM?.themeImage?.title || croppedImages?.original?.file?.name || FORM.themeImage?.source || FORM.themeImage?.thumbnailUrl || FORM.themeImage?.articleUrl) {
                                            <div class="image-title" [class.error-field]="validation?.afbeelding">
                                                <span>{{FORM?.themeImage?.title || FORM?.themeImage?.sourceFile?.mame || croppedImages?.original?.file?.name}}</span>
                                                <i class="im-icon im-icon-x-round"></i>
                                            </div>
                                            @if (validation?.afbeelding) {
                                                <span class="error-field-display ml-4">{{validation?.afbeelding}}</span>
                                            }
                                        } @else {
                                            <button class="image-input-button button tertiary" type="button">
                                                <i class="im-icon im-icon-plus-round"></i> Nieuw
                                            </button>
                                        }
                                    </div>

                                    @if (FORM?.themeImage?.title || croppedImages?.original?.file?.name || FORM.themeImage?.source || FORM.themeImage?.thumbnailUrl || FORM.themeImage?.articleUrl) {
                                        <div class="position-relative" appClickOutside (clickOutside)="confirmDeleteImage = false">
                                            <button class="button tertiary delete-red padding--xs" (click)="confirmDeleteImage = true" [class.disabled]="submitting"><i class="im-icon im-icon-trash delete-red icon-l mr-xxxs" style="transform: translateY(-1px);"></i><span>Verwijder afbeelding</span></button>
                                            <app-confirm-delete (confirm)="deleteImage();" (cancel)="confirmDeleteImage = false" [open]="confirmDeleteImage" [descr]="'Ben je zeker dat je de afbeelding wil verwijderen?'" positionClass="left right auto" positionClassV="bottom"></app-confirm-delete>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div class="display-value d-flex flex-column fit-content">
                                <div class="edit-image-crop-label">
                                    <div class="display-label mb-0">Afbeelding artikel pagina</div>
                                    @if (mode === 'edit' && FORM.themeImage?.articleUrl) {
                                        <button class="edit-image-crop" (click)="themeImageEdit()">
                                            <i class="im-icon im-icon-pencil-outlined"></i>
                                            Bewerken
                                        </button>
                                    }
                                </div>
                                @if (submittingImages) {
                                    <app-inline-spinner></app-inline-spinner>
                                } @else {
                                    @if (FORM.themeImage?.articleUrl) {
                                        <img class="display-value cropped-image" [src]="FORM?.themeImage?.articleUrl" alt="nieuwsbericht detail afbeelding">
                                    } @else {
                                        <span class="display-value">-</span>
                                    }
                                }
                            </div>
                            <div class="display-value d-flex flex-column fit-content">
                                <div class="edit-image-crop-label">
                                    <div class="display-label">Afbeelding overzicht</div>
                                    @if (mode === 'edit' && FORM.themeImage?.thumbnailUrl) {
                                        <button class="edit-image-crop" (click)="themeImageEdit()">
                                            <i class="im-icon im-icon-pencil-outlined"></i>
                                            Bewerken
                                        </button>
                                    }
                                </div>

                                @if (submittingImages) {
                                    <app-inline-spinner></app-inline-spinner>
                                } @else {
                                    @if (FORM.themeImage?.thumbnailUrl) {
                                        <img class="display-value cropped-image" [src]="FORM?.themeImage?.thumbnailUrl" alt="nieuwsbericht thumbnail">
                                    } @else {
                                        <span class="display-value">-</span>
                                    }
                                }
                            </div>
                            
                        </div>

                        <div class="display-content">
                            @if (mode === 'view') {
                                <div class="display-label">Datum laatste wijziging</div>
                                <div class="display-value d-flex flex-row">
                                    <span>{{data?.datum | formatTs: 'DD-MM-YYYY'}}</span>
                                </div>
                            }
                            @else {
                                <app-field-date 
                                    [model]="FORM.datum" 
                                    (modelChange)="FORM.datum = $event; formChange();" 
                                    label="Datum laatste wijziging"
                                    placeholder="dd-mm-yyyy"
                                    [error]="validation?.datum"
                                ></app-field-date>
                            }
                        </div>

                        <div class="display-content">
                            @if (mode === 'view') {
                                <div class="display-label">Gepubliceerd</div>
                                <div class="display-value d-flex flex-row">
                                    <span>{{data?.gepubliceerd | yesNo | display}}</span>
                                </div>
                            } @else {
                                <app-field-radios 
                                    class="d-block"
                                    label="Gepubliceerd"
                                    [model]="FORM.gepubliceerd ? 'yes' : 'no'"
                                    (modelChange)="FORM.gepubliceerd = ($event === 'yes' ? true : false); formChange();"
                                    [error]="validation?.gepubliceerd"
                                    [options]="yesNoOptions"
                                    [optionWidth]="80"
                                    [optionColumnWidth]=""
                                ></app-field-radios>
                            }
                        </div>

                        <div class="display-content">
                            @if (mode === 'view') {
                                <div class="display-label">Toon op homepage</div>
                                <div class="display-value d-flex flex-row">
                                    <span>{{data?.homepage | yesNo | display}}</span>
                                </div>
                            } @else {
                                <app-field-radios 
                                    class="d-block"
                                    label="Toon op homepage"
                                    [model]="FORM.homepage ? 'yes' : 'no'"
                                    (modelChange)="FORM.homepage = ($event === 'yes' ? true : false); formChange();"
                                    [error]="validation?.homepage"
                                    [options]="yesNoOptions"
                                    [optionWidth]="80"
                                ></app-field-radios>
                            }
                        </div>
                    </section>
        
                </div>


                @if (id) {
                    <app-history
                        [createTS]="data.createTS"
                        [createUsername]="data.createUsername"
                        [editTS]="data.editTS"
                        [editUsername]="data.editUsername"
                    ></app-history>
                }

            </div>
        </div>
    }
    
</div>